var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.buttonType == "insert"
                ? "Thêm khoảng tính toán"
                : "Sửa khoảng tính toán",
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            showClose: false,
            top: "2vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataInput",
              attrs: {
                "label-width": "130px",
                model: _vm.dataInput,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "inline-block", width: "45%" },
                  attrs: { label: "Chọn kiểu", prop: "category1" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.changeCategory1()
                        },
                      },
                      model: {
                        value: _vm.dataInput.category1,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataInput, "category1", $$v)
                        },
                        expression: "dataInput.category1",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "reduce" } }, [
                        _vm._v("Giảm tiền"),
                      ]),
                      _c("el-radio", { attrs: { label: "increase" } }, [
                        _vm._v("Thêm tiền"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    display: "inline-block",
                    width: "45%",
                    float: "right",
                  },
                  style:
                    _vm.dataInput.category1 == "reduce"
                      ? ""
                      : "visibility:hidden",
                  attrs: { label: "Loại tính toán", prop: "category2" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.dataInput.category2,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataInput, "category2", $$v)
                        },
                        expression: "dataInput.category2",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "dynamic" } }, [
                        _vm._v("Điểm danh"),
                      ]),
                      _c("el-radio", { attrs: { label: "fixed" } }, [
                        _vm._v("Cố định"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataInput.category2 == "fixed"
                ? _c(
                    "span",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "45%",
                          },
                          attrs: { label: "Chọn loại", prop: "typeFixed" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.dataInput.typeFixed,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataInput, "typeFixed", $$v)
                                },
                                expression: "dataInput.typeFixed",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "percent" } }, [
                                _vm._v("Phần trăm"),
                              ]),
                              _c("el-radio", { attrs: { label: "money" } }, [
                                _vm._v("Tiền"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            display: "inline-block",
                            width: "45%",
                            float: "right",
                          },
                          attrs: {
                            label:
                              _vm.dataInput.typeFixed == "money"
                                ? "Nhập Tiền"
                                : "Nhập phần trăm",
                            prop: "fixedData",
                          },
                        },
                        [
                          _vm.dataInput.typeFixed == "percent"
                            ? _c("el-input-number", {
                                attrs: { precision: 0, min: 0, max: 100 },
                                model: {
                                  value: _vm.dataInput.fixedData,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataInput, "fixedData", $$v)
                                  },
                                  expression: "dataInput.fixedData",
                                },
                              })
                            : _vm.dataInput.typeFixed == "money"
                            ? _c("el-currency-input", {
                                staticStyle: { width: "180px" },
                                model: {
                                  value: _vm.dataInput.fixedData,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataInput, "fixedData", $$v)
                                  },
                                  expression: "dataInput.fixedData",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataInput.category1 == "reduce" &&
              _vm.dataInput.category2 == "dynamic"
                ? _c(
                    "span",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Loại nghỉ", prop: "category3" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.changeCategogy3()
                                },
                              },
                              model: {
                                value: _vm.dataInput.category3,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataInput, "category3", $$v)
                                },
                                expression: "dataInput.category3",
                              },
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "Nghỉ không phân biệt có phép, ko phép, chưa điểm danh",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "absentAll" } },
                                    [_vm._v("Nghỉ chung ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "Nghỉ có phân biệt có phép, ko phép, chưa điểm danh",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "absentDetail" } },
                                    [_vm._v("Nghỉ chi tiết")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("hr"),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "45%",
                              },
                              attrs: {
                                label: "Chọn loại khoảng",
                                prop: "typeDynamic",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.clearDataRangeMethod()
                                    },
                                  },
                                  model: {
                                    value: _vm.dataInput.typeDynamic,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataInput,
                                        "typeDynamic",
                                        $$v
                                      )
                                    },
                                    expression: "dataInput.typeDynamic",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "percent" } },
                                    [_vm._v("Phần trăm")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "number" } },
                                    [_vm._v("Số lượng")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "45%",
                                float: "right",
                              },
                              attrs: {
                                label: "Chọn loại giảm",
                                prop: "typeDiscount",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.clearDataRangeMethod()
                                    },
                                  },
                                  model: {
                                    value: _vm.dataInput.typeDiscount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataInput,
                                        "typeDiscount",
                                        $$v
                                      )
                                    },
                                    expression: "dataInput.typeDiscount",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "percent" } },
                                    [_vm._v("Phần trăm")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "money" } },
                                    [_vm._v("Tiền")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.dataInput.category3 == "absentAll"
                            ? _c(
                                "span",
                                _vm._l(
                                  _vm.dataInput.dataList,
                                  function (data, index) {
                                    return _c(
                                      "el-form-item",
                                      {
                                        key: data.key,
                                        attrs: {
                                          label: "Giá trị " + (index + 1),
                                          prop: "dataList." + index + ".range",
                                          rules: {
                                            required: true,
                                            message:
                                              "Thông tin ko được để trống",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _vm.dataInput.typeDynamic == "percent"
                                          ? _c(
                                              "span",
                                              { staticClass: "text-class" },
                                              [_vm._v("Khoảng phần trăm")]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "text-class" },
                                              [_vm._v("Khoảng số lượng")]
                                            ),
                                        _vm.dataInput.typeDynamic == "percent"
                                          ? _c("el-input-number", {
                                              staticClass: "input-class",
                                              attrs: {
                                                size: "medium",
                                                precision: 0,
                                                min: 0,
                                                max: 100,
                                              },
                                              model: {
                                                value: data.range,
                                                callback: function ($$v) {
                                                  _vm.$set(data, "range", $$v)
                                                },
                                                expression: "data.range",
                                              },
                                            })
                                          : _c("el-input-number", {
                                              staticClass: "input-class",
                                              attrs: { min: 0, size: "medium" },
                                              model: {
                                                value: data.range,
                                                callback: function ($$v) {
                                                  _vm.$set(data, "range", $$v)
                                                },
                                                expression: "data.range",
                                              },
                                            }),
                                        _vm.dataInput.typeDiscount == "percent"
                                          ? _c(
                                              "span",
                                              { staticClass: "text-class" },
                                              [_vm._v("Phần trăm giảm")]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "text-class" },
                                              [_vm._v("Số tiền giảm")]
                                            ),
                                        _vm.dataInput.typeDiscount == "percent"
                                          ? _c("el-input-number", {
                                              staticClass: "input-class",
                                              attrs: {
                                                size: "medium",
                                                precision: 0,
                                                min: 0,
                                                max: 100,
                                              },
                                              model: {
                                                value: data.value,
                                                callback: function ($$v) {
                                                  _vm.$set(data, "value", $$v)
                                                },
                                                expression: "data.value",
                                              },
                                            })
                                          : _c("el-currency-input", {
                                              staticClass: "input-class",
                                              model: {
                                                value: data.value,
                                                callback: function ($$v) {
                                                  _vm.$set(data, "value", $$v)
                                                },
                                                expression: "data.value",
                                              },
                                            }),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "medium",
                                              type: "danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.removeDomain(data)
                                              },
                                            },
                                          },
                                          [_vm._v("Xóa")]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _vm.dataInput.category3 == "absentDetail"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "10px",
                                        "margin-left": "300px",
                                      },
                                      model: {
                                        value: _vm.dataInput.absentType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataInput,
                                            "absentType",
                                            $$v
                                          )
                                        },
                                        expression: "dataInput.absentType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "absentYes" } },
                                        [_vm._v("Nghỉ có phép")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "absentNo" } },
                                        [_vm._v("Nghỉ không phép")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "noAttendance" } },
                                        [_vm._v("Chưa điểm danh")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.dataInput.absentType == "absentYes"
                                    ? _c(
                                        "span",
                                        _vm._l(
                                          _vm.dataInput.absentYesList,
                                          function (data, index) {
                                            return _c(
                                              "el-form-item",
                                              {
                                                key: data.key,
                                                attrs: {
                                                  label:
                                                    "Nghỉ có phép " +
                                                    (index + 1),
                                                  prop:
                                                    "absentYesList." +
                                                    index +
                                                    ".range",
                                                  rules: {
                                                    required: true,
                                                    message:
                                                      "Thông tin ko được để trống",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Khoảng phần trăm"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Khoảng số lượng"
                                                        ),
                                                      ]
                                                    ),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        size: "medium",
                                                        precision: 0,
                                                        min: 0,
                                                        max: 100,
                                                      },
                                                      model: {
                                                        value: data.range,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "range",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.range",
                                                      },
                                                    })
                                                  : _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        min: 0,
                                                        size: "medium",
                                                      },
                                                      model: {
                                                        value: data.range,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "range",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.range",
                                                      },
                                                    }),
                                                _vm.dataInput.typeDiscount ==
                                                "percent"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [_vm._v("Phần trăm giảm")]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [_vm._v("Số tiền giảm")]
                                                    ),
                                                _vm.dataInput.typeDiscount ==
                                                "percent"
                                                  ? _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        size: "medium",
                                                        precision: 0,
                                                        min: 0,
                                                        max: 100,
                                                      },
                                                      model: {
                                                        value: data.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.value",
                                                      },
                                                    })
                                                  : _c("el-currency-input", {
                                                      staticClass:
                                                        "input-class",
                                                      model: {
                                                        value: data.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.value",
                                                      },
                                                    }),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "medium",
                                                      type: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.removeDomain(
                                                          data
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Xóa")]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataInput.absentType == "absentNo"
                                    ? _c(
                                        "span",
                                        _vm._l(
                                          _vm.dataInput.absentNoList,
                                          function (data, index) {
                                            return _c(
                                              "el-form-item",
                                              {
                                                key: data.key,
                                                attrs: {
                                                  label:
                                                    "Nghỉ có phép " +
                                                    (index + 1),
                                                  prop:
                                                    "absentNoList." +
                                                    index +
                                                    ".range",
                                                  rules: {
                                                    required: true,
                                                    message:
                                                      "Thông tin ko được để trống",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Khoảng phần trăm"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Khoảng số lượng"
                                                        ),
                                                      ]
                                                    ),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        size: "medium",
                                                        precision: 0,
                                                        min: 0,
                                                        max: 100,
                                                      },
                                                      model: {
                                                        value: data.range,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "range",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.range",
                                                      },
                                                    })
                                                  : _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        min: 0,
                                                        size: "medium",
                                                      },
                                                      model: {
                                                        value: data.range,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "range",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.range",
                                                      },
                                                    }),
                                                _vm.dataInput.typeDiscount ==
                                                "percent"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [_vm._v("Phần trăm giảm")]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [_vm._v("Số tiền giảm")]
                                                    ),
                                                _vm.dataInput.typeDiscount ==
                                                "percent"
                                                  ? _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        size: "medium",
                                                        precision: 0,
                                                        min: 0,
                                                        max: 100,
                                                      },
                                                      model: {
                                                        value: data.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.value",
                                                      },
                                                    })
                                                  : _c("el-currency-input", {
                                                      staticClass:
                                                        "input-class",
                                                      model: {
                                                        value: data.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.value",
                                                      },
                                                    }),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "medium",
                                                      type: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.removeDomain(
                                                          data
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Xóa")]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataInput.absentType == "noAttendance"
                                    ? _c(
                                        "span",
                                        _vm._l(
                                          _vm.dataInput.noAttendanceList,
                                          function (data, index) {
                                            return _c(
                                              "el-form-item",
                                              {
                                                key: data.key,
                                                attrs: {
                                                  label:
                                                    "Nghỉ có phép " +
                                                    (index + 1),
                                                  prop:
                                                    "noAttendanceList." +
                                                    index +
                                                    ".range",
                                                  rules: {
                                                    required: true,
                                                    message:
                                                      "Thông tin ko được để trống",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Khoảng phần trăm"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Khoảng số lượng"
                                                        ),
                                                      ]
                                                    ),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        size: "medium",
                                                        precision: 0,
                                                        min: 0,
                                                        max: 100,
                                                      },
                                                      model: {
                                                        value: data.range,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "range",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.range",
                                                      },
                                                    })
                                                  : _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        min: 0,
                                                        size: "medium",
                                                      },
                                                      model: {
                                                        value: data.range,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "range",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.range",
                                                      },
                                                    }),
                                                _vm.dataInput.typeDiscount ==
                                                "percent"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [_vm._v("Phần trăm giảm")]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-class",
                                                      },
                                                      [_vm._v("Số tiền giảm")]
                                                    ),
                                                _vm.dataInput.typeDiscount ==
                                                "percent"
                                                  ? _c("el-input-number", {
                                                      staticClass:
                                                        "input-class",
                                                      attrs: {
                                                        size: "medium",
                                                        precision: 0,
                                                        min: 0,
                                                        max: 100,
                                                      },
                                                      model: {
                                                        value: data.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.value",
                                                      },
                                                    })
                                                  : _c("el-currency-input", {
                                                      staticClass:
                                                        "input-class",
                                                      model: {
                                                        value: data.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            data,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.value",
                                                      },
                                                    }),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "medium",
                                                      type: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.removeDomain(
                                                          data
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Xóa")]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addDomain()
                                    },
                                  },
                                },
                                [_vm._v("Thêm khoảng")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.dataInput.category1 == "reduce" &&
          _vm.dataInput.category2 == "dynamic"
            ? _c("span", [
                _c("hr"),
                _vm.dataInput.category3 == "absentAll"
                  ? _c(
                      "div",
                      { staticStyle: { width: "50%", margin: "0 auto" } },
                      [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("Thông tin giảm"),
                        ]),
                        _c(
                          "el-table",
                          { attrs: { data: _vm.dataInput.dataList } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "STT",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: { label: "Khoảng", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm.dataInput.dataList.indexOf(
                                          scope.row
                                        ) +
                                          1 <
                                        _vm.dataInput.dataList.length
                                          ? _c("span", [
                                              _vm._v(_vm._s(scope.row.range)),
                                              _vm.dataInput.typeDynamic ==
                                              "percent"
                                                ? _c("span", [_vm._v("%")])
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                <=\n                " +
                                                  _vm._s(
                                                    _vm.dataInput.dataList[
                                                      _vm.dataInput.dataList.indexOf(
                                                        scope.row
                                                      ) + 1
                                                    ].range
                                                  ) +
                                                  "\n                "
                                              ),
                                              _vm.dataInput.typeDynamic ==
                                              "percent"
                                                ? _c("span", [_vm._v("%")])
                                                : _vm._e(),
                                            ])
                                          : _c("span", [
                                              _vm._v(_vm._s(scope.row.range)),
                                              _vm.dataInput.typeDynamic ==
                                              "percent"
                                                ? _c("span", [_vm._v("%")])
                                                : _vm._e(),
                                              _vm._v("\n                <"),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4066110980
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "Giá trị giảm", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm.dataInput.typeDiscount == "percent"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.value) + "%"
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.dataInput.typeDiscount == "money"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatCurrencyNew")(
                                                    scope.row.value
                                                  )
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3441048784
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.dataInput.category3 == "absentDetail"
                  ? _c("span", [
                      _c(
                        "span",
                        { staticClass: "table-3" },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Nghỉ có phép")]
                          ),
                          _c(
                            "el-table",
                            { attrs: { data: _vm.dataInput.absentYesList } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "STT",
                                  width: "50",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "Khoảng", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.dataInput.absentYesList.indexOf(
                                            scope.row
                                          ) +
                                            1 <
                                          _vm.dataInput.absentYesList.length
                                            ? _c("span", [
                                                _vm._v(_vm._s(scope.row.range)),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                  <=\n                  " +
                                                    _vm._s(
                                                      _vm.dataInput
                                                        .absentYesList[
                                                        _vm.dataInput.absentYesList.indexOf(
                                                          scope.row
                                                        ) + 1
                                                      ].range
                                                    ) +
                                                    "\n                  "
                                                ),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                              ])
                                            : _c("span", [
                                                _vm._v(_vm._s(scope.row.range)),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                                _vm._v("\n                  <"),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3073727076
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Giá trị giảm",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.dataInput.typeDiscount ==
                                          "percent"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.value) + "%"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.dataInput.typeDiscount == "money"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatCurrencyNew")(
                                                      scope.row.value
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3441048784
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "table-3" },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Nghỉ không phép")]
                          ),
                          _c(
                            "el-table",
                            { attrs: { data: _vm.dataInput.absentNoList } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "STT",
                                  width: "50",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "Khoảng", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.dataInput.absentNoList.indexOf(
                                            scope.row
                                          ) +
                                            1 <
                                          _vm.dataInput.absentNoList.length
                                            ? _c("span", [
                                                _vm._v(_vm._s(scope.row.range)),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                  <=\n                  " +
                                                    _vm._s(
                                                      _vm.dataInput
                                                        .absentNoList[
                                                        _vm.dataInput.absentNoList.indexOf(
                                                          scope.row
                                                        ) + 1
                                                      ].range
                                                    ) +
                                                    "\n                  "
                                                ),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                              ])
                                            : _c("span", [
                                                _vm._v(_vm._s(scope.row.range)),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                                _vm._v("\n                  <"),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1781413316
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Giá trị giảm",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.dataInput.typeDiscount ==
                                          "percent"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.value) + "%"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.dataInput.typeDiscount == "money"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatCurrencyNew")(
                                                      scope.row.value
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3441048784
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "table-3" },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("Chưa điểm danh")]
                          ),
                          _c(
                            "el-table",
                            { attrs: { data: _vm.dataInput.noAttendanceList } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "STT",
                                  width: "50",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: { label: "Khoảng", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.dataInput.noAttendanceList.indexOf(
                                            scope.row
                                          ) +
                                            1 <
                                          _vm.dataInput.noAttendanceList.length
                                            ? _c("span", [
                                                _vm._v(_vm._s(scope.row.range)),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                  <=\n                  " +
                                                    _vm._s(
                                                      _vm.dataInput
                                                        .noAttendanceList[
                                                        _vm.dataInput.noAttendanceList.indexOf(
                                                          scope.row
                                                        ) + 1
                                                      ].range
                                                    ) +
                                                    "\n                  "
                                                ),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                              ])
                                            : _c("span", [
                                                _vm._v(_vm._s(scope.row.range)),
                                                _vm.dataInput.typeDynamic ==
                                                "percent"
                                                  ? _c("span", [_vm._v("%")])
                                                  : _vm._e(),
                                                _vm._v("\n                  <"),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2922372996
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Giá trị giảm",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.dataInput.typeDiscount ==
                                          "percent"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.value) + "%"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.dataInput.typeDiscount == "money"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatCurrencyNew")(
                                                      scope.row.value
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3441048784
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.buttonType == "insert"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("OK")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }