<template>
  <el-dialog
    title="Tạo khoản"
    :visible.sync="dialogVisible"
    width="1150px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    top="3vh"
  >
    <el-form
      label-width="160px"
      :model="dataInput"
      label-position="left"
      :rules="rules"
      ref="dataInput"
    >
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form-item label="Tên khoản" prop="name">
            <el-input v-model="dataInput.name" placeholder="Nhập tên khoản"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Loại" prop="category">
            <el-select
              placeholder="Loại"
              @change="resetAttendanceMethod()"
              v-model="dataInput.category"
            >
              <el-option label="Thu" value="in"></el-option>
              <el-option label="Chi" value="out"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          :rows="1"
          type="textarea"
          autosize
          v-model="dataInput.description"
          placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Đơn vị tính" prop="unit">
            <el-input v-model="dataInput.unit" placeholder="Nhập đơn vị tính"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Số lượng" prop="number">
            <el-input-number
              v-model="dataInput.number"
              :precision="0"
              :min="1"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Đơn giá" prop="price">
            <el-currency-input v-model="dataInput.price" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Giảm giá" prop="discount">
            <el-radio-group v-model="dataInput.discount">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="dataInput.discount">
          <el-form-item label="Loại giảm giá" prop="discountType">
            <el-select v-model="dataInput.discountType" placeholder="Loại">
              <el-option label="Phần trăm" value="percent"></el-option>
              <el-option label="Tiền" value="money"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="dataInput.discount">
          <el-form-item
            :label="dataInput.discountType == 'percent' ? 'Phần trăm giảm' : 'Tiền giảm'"
            prop="discountNumber"
          >
            <el-input-number
              v-if="dataInput.discountType == 'percent'"
              v-model="dataInput.discountNumber"
              :precision="0"
              :min="0"
              :max="100"
            >
            </el-input-number>
            <el-currency-input
              v-else-if="dataInput.discountType == 'money'"
              v-model="dataInput.discountNumber"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col v-if="dataInput.discount">
          <el-form-item label="Đơn giá khi giảm" prop="discountPrice">
            <currency-input
              disabled
              style="
                padding: 10px;
                width: 98%;
                text-align: right;
                background: #e7e9ec;
                border: 1px solid #dcdfe6;
                font-weight: bold;
              "
              v-model="dataInput.discountPrice"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Tự động đăng ký" prop="active">
            <el-radio-group v-model="dataInput.active">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Kiểu đóng" prop="type">
            <el-radio-group v-model="dataInput.type">
              <el-radio label="single">Một lần</el-radio>
              <el-radio label="multiple">Nhiều lần</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="dataInput.type == 'multiple'" :gutter="20">
        <el-col :span="24">
          <el-form-item label="Tháng áp dụng">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >Tất cả</el-checkbox
            >
            <el-checkbox v-model="dataInput.t1" label="T1"></el-checkbox>
            <el-checkbox v-model="dataInput.t2" label="T2"></el-checkbox>
            <el-checkbox v-model="dataInput.t3" label="T3"></el-checkbox>
            <el-checkbox v-model="dataInput.t4" label="T4"></el-checkbox>
            <el-checkbox v-model="dataInput.t5" label="T5"></el-checkbox>
            <el-checkbox v-model="dataInput.t6" label="T6"></el-checkbox>
            <el-checkbox v-model="dataInput.t7" label="T7"></el-checkbox>
            <el-checkbox v-model="dataInput.t8" label="T8"></el-checkbox>
            <el-checkbox v-model="dataInput.t9" label="T9"></el-checkbox>
            <el-checkbox v-model="dataInput.t10" label="T10"></el-checkbox>
            <el-checkbox v-model="dataInput.t11" label="T11"></el-checkbox>
            <el-checkbox v-model="dataInput.t12" label="T12"></el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="!!dataInput.type" :gutter="20">
        <el-col :span="8">
          <el-form-item label="Hết hạn" prop="expired">
            <el-radio-group v-model="dataInput.expired">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col v-if="!!dataInput.type && dataInput.expired" :span="12">
          <el-form-item
            v-if="dataInput.type == 'single'"
            label="Hạn thu"
            prop="endDateExpired"
          >
            <el-date-picker
              v-model="dataInput.endDateExpired"
              type="date"
              placeholder="Chọn ngày"
              value-format="yyyy-MM-dd"
              format="dd-MM-yyyy"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            v-if="dataInput.type == 'multiple'"
            label="Hạn tháng thu"
            prop="monthNumber"
          >
            <el-radio-group v-model="dataInput.monthNumber">
              <el-radio :label="0">Tháng hiện tại</el-radio>
              <el-radio :label="1">Tháng kế tiếp</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="dataInput.type == 'multiple' && dataInput.expired" :gutter="20">
        <el-col :span="8">
          <el-form-item label="Tháng 2" prop="febNumberExpired">
            <el-input-number v-model="dataInput.febNumberExpired" :min="1" :max="28">
            </el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Tháng 4,6,9,11" prop="smallNumberExpired">
            <el-input-number v-model="dataInput.smallNumberExpired" :min="1" :max="30">
            </el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Tháng 1,3,5,7,8,10,12" prop="largeNumberExpired">
            <el-input-number v-model="dataInput.largeNumberExpired" :min="1" :max="31">
            </el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="dataInput.type == 'multiple'">
        <el-col :span="8">
          <el-form-item label="Áp dụng điểm danh" prop="attendance">
            <el-radio-group v-model="dataInput.attendance">
              <el-radio :label="true">Có</el-radio>
              <el-radio :label="false">Không</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="dataInput.attendance">
          <el-form-item label="Kiểu trả" prop="attendancePaid">
            <el-radio-group
              v-model="dataInput.attendancePaid"
              @change="resetAttendanceMethod()"
            >
              <el-radio label="before">Trả trước</el-radio>
              <el-radio label="after">Trả sau</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="dataInput.type == 'multiple' && dataInput.attendance">
        <span v-if="dataInput.attendancePaid == 'before'">
          <el-col :span="8">
            <el-form-item label="Loại điểm danh" prop="attendanceType">
              <el-select
                v-model="dataInput.attendanceType"
                placeholder="Chọn loại điểm danh"
              >
                <el-option
                  v-for="item in this.$funcData.funcAttendanceTypeNewList()"
                  :key="item.key"
                  :value="item.key"
                  :label="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="dataInput.attendanceType != 'pickupLate'">
            <el-form-item label="Chi tiết" prop="attendanceDetail">
              <el-select
                v-model="dataInput.attendanceDetail"
                placeholder="Chọn chi tiết"
                style="width: 250px"
              >
                <el-option
                  v-for="item in attendanceDetailNewList"
                  :key="item.key"
                  :value="item.key"
                  :label="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </span>
        <span v-else-if="dataInput.attendancePaid == 'after'">
          <el-col :span="8">
            <el-form-item label="Loại điểm danh" prop="attendanceType">
              <el-select
                v-model="dataInput.attendanceType"
                placeholder="Chọn loại điểm danh"
              >
                <el-option
                  v-for="item in attendanceTypeList"
                  :key="item.key"
                  :value="item.key"
                  :label="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="dataInput.attendanceType != 'pickupLate'">
            <el-form-item label="Chi tiết" prop="attendanceDetail">
              <el-select
                v-model="dataInput.attendanceDetail"
                placeholder="Chọn chi tiết"
                style="width: 250px"
              >
                <el-option
                  v-for="item in attendanceDetailList"
                  :key="item.key"
                  :value="item.key"
                  :label="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </span>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" size="medium" @click="closeDialog()">
        <i class="el-icon-circle-close" />
        <span>{{ $t("button.close") }}</span>
      </el-button>
      <el-button
        type="success"
        size="medium"
        :loading="loadingButton"
        mini
        @click="submitForm()"
      >
        <i class="el-icon-circle-check" />
        <span>{{ $t("button.save") }}</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import ElCurrencyInput from "@/components/ElCurrencyInput.vue";
import FnFeesService from "@/services/FinanceKids/FnFeesService";

const valAttendanceTypeList = [
  { key: "goSchool", value: "Điểm danh đi học" },
  { key: "absentYes", value: "Nghỉ có phép" },
  { key: "absentNo", value: "Nghỉ không phép" },
  { key: "eat", value: "Điểm danh ăn" },
  { key: "pickupLate", value: "Đón muộn" },
];
const valAttendanceGoSchoolList = [
  { key: "morning", value: "Buổi sáng" },
  { key: "afternoon", value: "Buổi chiều" },
  { key: "evening", value: "Buổi tối" },
  { key: "allDay", value: "Cả ngày" },
];
const valAttendanceEatList = [
  { key: "eatBreakfast", value: "Bữa sáng" },
  { key: "eatBreakfastSecond", value: "Bữa phụ sáng" },
  { key: "eatLunch", value: "Bữa trưa" },
  { key: "eatAfternoon", value: "Bữa chiều" },
  { key: "eatAfternoonSecond", value: "Bữa phụ chiều" },
  { key: "eatDinner", value: "Bữa tối" },
  { key: "allDay", value: "Cả ngày" },
];

const valAttendanceDetailInNewList = [
  { key: "eatBreakfast1", value: "Thu tiền bữa sáng (1)" },
  { key: "eatRemain1", value: "Thu tiền bữa còn lại (1)" },
  { key: "eatDay2", value: "Thu tiền gói một ngày (2)" },
];
// const valAttendanceDetailOutNewList = [
//   { key: "eatBreakfastOut1", value: "Trả lại tiền bữa sáng (1)" },
//   { key: "eatRemainOut1", value: "Trả lại tiền bữa còn lại (1)" },
//   { key: "eatDayOut2", value: "Trả lại tiền gói một ngày (2)" },
//   { key: "eatBreakfastOut2", value: "Trả lại tiền bữa sáng (2)" },
// ];
// const valAttendanceDetailArriveInNewList = [
//   { key: "arriveGoSchool", value: "Thu tiền đi học" },
// ];
// const valAttendanceDetailArriveOutNewList = [
//   { key: "arriveAbsentYes", value: "Trả lại tiền nghỉ có phép" },
//   { key: "arriveAbsentNo", value: "Trả lại tiền nghỉ không phép" },
// ];

export default {
  components: {
    ElCurrencyInput,
  },
  props: {
    dialogVisible: null,
  },

  data() {
    return {
      dataInput: {
        name: "",
        number: 1,
        type: "",
        unit: "",
        category: "in",
        description: "",
        discount: false,
        discountType: "percent",
        price: Number,
        expired: false,
        active: true,
        attendance: false,
        attendancePaid: "",
        attendanceType: "",
        attendanceDetail: "",
        febNumberExpired: "",
        smallNumberExpired: "",
        largeNumberExpired: "",

        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        monthNumber: 0,
      },
      attendanceTypeList: valAttendanceTypeList,
      attendanceGoSchoolList: valAttendanceGoSchoolList,
      attendanceEatList: valAttendanceEatList,
      attendanceDetailList: [],
      attendanceDetailInNewList: valAttendanceDetailInNewList,
      // attendanceDetailOutNewList: valAttendanceDetailOutNewList,
      // attendanceDetailArriveInNewList: valAttendanceDetailArriveInNewList,
      // attendanceDetailArriveOutNewList: valAttendanceDetailArriveOutNewList,
      attendanceDetailNewList: [],
      checkAll: false,
      loadingButton: false,
      rules: {
        name: [
          {
            required: true,
            message: "Tên khoản không được để trống",
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            message: "Đơn vị tính không được để trống",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "Số lượng không được để trống",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "Đơn giá không được để trống",
            trigger: "blur",
          },
        ],
        discountType: [
          {
            required: true,
            message: "Loại giảm giá không được để trống",
            trigger: "change",
          },
        ],
        discountNumber: [
          {
            required: true,
            message: "Thông tin không được để trống",
            trigger: "blur",
          },
        ],
        expired: [
          {
            required: true,
            message: "Hết hạn không được để trống",
            trigger: "change",
          },
        ],
        attendance: [
          {
            required: true,
            message: "Áp dụng điểm danh không được để trống",
            trigger: "change",
          },
        ],
        attendancePaid: [
          {
            required: true,
            message: "Kiểu trả không được để trống",
            trigger: "change",
          },
        ],
        attendanceType: [
          {
            required: true,
            message: "Loại điểm danh không được để trống",
            trigger: "change",
          },
        ],
        attendanceDetail: [
          {
            required: true,
            message: "Chi tiết không được để trống",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Kiểu đóng không được để trống",
            trigger: "change",
          },
        ],
        endDateExpired: [
          {
            required: true,
            message: "Ngày hết hạn không được để trống",
            trigger: "change",
          },
        ],
        febNumberExpired: [
          {
            required: true,
            message: "Số ngày không được để trống",
            trigger: "blur",
          },
        ],
        smallNumberExpired: [
          {
            required: true,
            message: "Số ngày không được để trống",
            trigger: "blur",
          },
        ],
        largeNumberExpired: [
          {
            required: true,
            message: "Số ngày không được để trống",
            trigger: "blur",
          },
        ],
        category: [
          {
            required: true,
            message: "Loại không được để trống",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    dataInput: {
      handler: function () {
        let dataInputWatch = this.dataInput;
        let discountNumberWatch = dataInputWatch.discountNumber;
        let discountPriceWatch = dataInputWatch.price;
        let priceWatch = dataInputWatch.price;
        if (
          discountNumberWatch != undefined &&
          discountNumberWatch != "" &&
          discountNumberWatch != 0 &&
          priceWatch != undefined &&
          priceWatch != "" &&
          priceWatch != 0
        ) {
          if (dataInputWatch.discountType == "percent") {
            discountPriceWatch = Number.parseInt(
              discountPriceWatch * ((100 - discountNumberWatch) / 100)
            );
          } else if (dataInputWatch.discountType == "money") {
            if (discountNumberWatch <= priceWatch) {
              discountPriceWatch = discountPriceWatch - discountNumberWatch;
            } else {
              this.$alert("Số tiền giảm không thể lớn hơn đơn giá", "Thông báo!", {
                confirmButtonText: "Đã hiểu",
                callback: () => {
                  this.dataInput.discountNumber = 0;
                },
              });
            }
          }
        }
        this.dataInput.discountPrice = discountPriceWatch;
      },
      deep: true,
    },
    changeAttendanceTypeComputed() {
      let dataInputComputed = this.dataInput;
      dataInputComputed.attendanceDetail = "";
      if (dataInputComputed.attendancePaid == "before") {
        if (dataInputComputed.attendanceType == "eatNew") {
          this.attendanceDetailNewList =
            dataInputComputed.category == "in"
              ? this.attendanceDetailInNewList
              : this.$funcData.getAttendanceDetailOutNewList();
        } else if (dataInputComputed.attendanceType == "arriveNew") {
          this.attendanceDetailNewList =
            dataInputComputed.category == "in"
              ? this.$funcData.getAttendanceDetailArriveInList()
              : this.$funcData.getAttendanceDetailArriveOutList();
        }
      } else if (dataInputComputed.attendancePaid == "after") {
        if (
          dataInputComputed.attendanceType == "absentYes" ||
          dataInputComputed.attendanceType == "absentNo"
        ) {
          this.attendanceDetailList = this.attendanceGoSchoolList;
        } else if (dataInputComputed.attendanceType == "goSchool") {
          this.attendanceDetailList = this.$funcData.getAttendanceGoSchooList();
        } else if (dataInputComputed.attendanceType == "eat") {
          this.attendanceDetailList = this.attendanceEatList;
        } else if (dataInputComputed.attendanceType == "pickupLate") {
          this.attendanceDetailList = [];
        }
      }
    },
  },
  computed: {
    changeAttendanceTypeComputed() {
      return [this.dataInput.attendanceType, this.dataInput.category];
    },
  },
  methods: {
    closeDialog() {
      this.$emit("dialog-close");
      setTimeout(() => {
        this.loadingButton = false;
        this.resetData();
        this.$refs["dataInput"].resetFields();
      }, 300);
    },
    resetData() {
      this.dataInput.attendanceType = "";
      this.dataInput.attendanceDetail = "";
      this.dataInput.febNumberExpired = "";
      this.dataInput.smallNumberExpired = "";
      this.dataInput.largeNumberExpired = "";
      this.checkAll = false;
      this.dataInput.t1 = false;
      this.dataInput.t2 = false;
      this.dataInput.t3 = false;
      this.dataInput.t4 = false;
      this.dataInput.t5 = false;
      this.dataInput.t6 = false;
      this.dataInput.t7 = false;
      this.dataInput.t8 = false;
      this.dataInput.t9 = false;
      this.dataInput.t10 = false;
      this.dataInput.t11 = false;
      this.dataInput.t12 = false;
    },
    handleCheckAllChange(val) {
      let dataInputLocal = this.dataInput;
      dataInputLocal.t1 = val;
      dataInputLocal.t2 = val;
      dataInputLocal.t3 = val;
      dataInputLocal.t4 = val;
      dataInputLocal.t5 = val;
      dataInputLocal.t6 = val;
      dataInputLocal.t7 = val;
      dataInputLocal.t8 = val;
      dataInputLocal.t9 = val;
      dataInputLocal.t10 = val;
      dataInputLocal.t11 = val;
      dataInputLocal.t12 = val;
    },
    checkBeforeSubmit() {
      let dataInputCheck = this.dataInput;
      if (dataInputCheck.type == "multiple") {
        if (
          !dataInputCheck.t1 &&
          !dataInputCheck.t2 &&
          !dataInputCheck.t3 &&
          !dataInputCheck.t4 &&
          !dataInputCheck.t5 &&
          !dataInputCheck.t6 &&
          !dataInputCheck.t7 &&
          !dataInputCheck.t8 &&
          !dataInputCheck.t9 &&
          !dataInputCheck.t10 &&
          !dataInputCheck.t11 &&
          !dataInputCheck.t12
        ) {
          this.$message({
            message: "Chưa chọn tháng áp dụng",
            type: "error",
          });
          throw null;
        }
      }
    },
    resetAttendanceMethod() {
      this.dataInput.attendanceType = "";
      this.dataInput.attendanceDetail = "";
    },
    submitForm() {
      this.$refs["dataInput"].validate((valid) => {
        if (valid) {
          this.checkBeforeSubmit();
          this.loadingButton = true;
          FnFeesService.createPackage(this.dataInput)
            .then((resp) => {
              this.$message({
                message: resp.data.message,
                type: "success",
              });
              setTimeout(() => {
                this.closeDialog();
              }, 500);
            })
            .catch((err) => {
              let messageText =
                err.response.status == 403
                  ? this.$permissionDenied
                  : err.response.data.message;
              this.$message({
                message: messageText,
                type: "error",
              });
            })
            .finally(() => {
              setTimeout(() => {
                this.loadingButton = false;
              }, 500);
            });
        } else {
          console.log("error validate data!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}
/deep/.el-dialog__body {
  padding-bottom: 5px;
}
/deep/.el-input-number__decrease,
/deep/.el-input-number__increase {
  z-index: 1;
}
.el-input-number {
  width: 100%;
}
</style>
