var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.dataTranfer.buttonType == "create"
                ? "Tạo khoảng đính kèm"
                : " Sửa khoảng đính kèm",
            visible: _vm.dialogVisible,
            width: "1000px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataInput",
              attrs: {
                "label-width": "150px",
                model: _vm.dataInput,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _vm.dataTranfer.buttonType == "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Chọn khoản",
                        prop: "idPackage",
                        rules: [
                          {
                            required: true,
                            message: "Khoản không được để trống",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "Chọn khoản", filterable: "" },
                          model: {
                            value: _vm.dataInput.idPackage,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "idPackage", $$v)
                            },
                            expression: "dataInput.idPackage",
                          },
                        },
                        _vm._l(_vm.packageList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { value: item.id, label: item.name },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" -\n            "),
                              item.category == "in"
                                ? _c("span", [_vm._v("Thu")])
                                : _c("span", [_vm._v("Chi")]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "Tên khoản", prop: "idPackage" } },
                    [_c("span", [_vm._v(_vm._s(_vm.dataTranfer.packageName))])]
                  ),
              _c(
                "el-form-item",
                { attrs: { label: "Tên khoản đính kèm", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "Nhập tên khoản đính kèm",
                    },
                    model: {
                      value: _vm.dataInput.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "name", $$v)
                      },
                      expression: "dataInput.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Mô tả", prop: "note" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: "",
                      type: "textarea",
                      placeholder: "Nhập mô tả",
                    },
                    model: {
                      value: _vm.dataInput.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "note", $$v)
                      },
                      expression: "dataInput.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "inline-block", width: "20%" },
                  attrs: { label: "Kích hoạt", prop: "active" },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content:
                          _vm.dataTranfer.type == "root"
                            ? "Trạng thái kích hoạt"
                            : _vm.dataTranfer.type == "default"
                            ? "Khi khoản học sinh được tạo thì khoản đính kèm được tạo theo"
                            : "Khoản đính kèm được áp dụng cho việc tính toán học phí",
                        placement: "right",
                      },
                    },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.dataInput.active,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInput, "active", $$v)
                          },
                          expression: "dataInput.active",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    display: "inline-block",
                    width: "65%",
                    float: "right",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "text-decoration": "underline" },
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.insertRangeMethod()
                        },
                      },
                    },
                    [_vm._v("Thêm khoảng tính toán")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                "highlight-current-row": "",
                "empty-text": _vm.textTable,
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.dataInput.moneyList,
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.$funcCommon.tableHeaderColorFunction,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Kiểu", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.category1 == "reduce"
                          ? _c("span", [_vm._v("Giảm tiền")])
                          : scope.row.category1 == "increase"
                          ? _c("span", [_vm._v("Thêm tiền")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Loại tính toán", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.category1 == "reduce"
                          ? [
                              scope.row.category2 == "dynamic"
                                ? _c("span", [_vm._v("Điểm danh")])
                                : scope.row.category2 == "fixed"
                                ? _c("span", [_vm._v("Cố định")])
                                : _vm._e(),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: { label: "Loại nghỉ", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.category2 == "dynamic"
                          ? [
                              scope.row.category3 == "absentAll"
                                ? _c("span", [_vm._v("Nghỉ chung")])
                                : scope.row.category3 == "absentDetail"
                                ? _c("span", [_vm._v("Nghỉ chi tiết")])
                                : _vm._e(),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  width: "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.updateRangeMethod(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.update")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.removeRangeMethod(scope.row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("RangeExtendDialog", {
        ref: "RangeExtendDialog",
        attrs: { dialogVisible: _vm.showRangeExtendDialog },
        on: { "dialog-close": _vm.closeRangeExtendDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }